<template>
  <div>
    <div
      class="spinner-container-edit"
      :class="{ 'blur-background': isEditLoad }"
      v-if="isEditLoad"
    >
      <div class="spinner-container">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </div>
    <b-modal
      no-close-on-backdrop
      v-model="internalModal"
      id="main-modal-center"
      hide-footer
      hide-header
      centered
      size="small"
    >
      <b-card-text>
        <div class="header text-right">
          <div class="header-container">
            <h3 class="app-title sm mt-1 mb-2" style="margin-left: 5px">
              <feather-icon
                @click="backToViewPostModal"
                v-if="isEdit"
                icon="ArrowLeftCircleIcon"
                size="30"
                class="mr-1"
                style="cursor: pointer; margin-bottom:3px"
              ></feather-icon>
              {{ isEdit ? "Edit Local Post" : "Create Local Post" }}
            </h3>
            <b-button
              v-if="!isEdit"
              @click="closeClientPopUp"
              variant="flat-primary"
              style="margin-top: -10px;margin-right:-18px"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
            <b-button
              v-if="isEdit"
              @click="closeEditClientPopUp"
              variant="flat-primary"
              style="margin-top: -10px;margin-right:-18px"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
        </div>
        <b-row>
          <b-col cols="12">
            <div class="pl-2 pr-2 edit-form">
              <div class="form-fields">
                <b-form-group>
                  <label for="client-name"
                    >Description <span class="text-danger ">*</span></label
                  >
                  <b-form-textarea
                    id="textarea-lazy"
                    placeholder="Add a Description"
                    rows="3"
                    v-model="description"
                    @input="updateCharacterCount"
                  ></b-form-textarea>
                  <span style="font-size:11px; float:right; margin-top:2px"
                    >{{ characterCount }}/1500</span
                  >
                </b-form-group>
                <b-form-group style="margin-top: -25px;">
                  <label for="client-name"
                    >Upload Image <span class="text-danger ">*</span></label
                  >
                  <br />
                  <span
                    v-if="this.imageUploadWarning"
                    class="text-danger"
                    style="font-size: 12px;"
                    >Invalid file type. Only JPEG and PNG files are
                    allowed.</span
                  >
                  <div class="image-container">
                    <div class="grid-container">
                      <div class="grid-item upload-container">
                        <div v-if="filePreviews.length == 0" class="cardUpload">
                          <input
                            ref="fileInput"
                            type="file"
                            @change="handleFileChange"
                            style="display: none;width:50px"
                          />
                          <b-button
                            variant="light-outline"
                            @click="openFileInput"
                          >
                            <span style="font-weight:500;background:white">
                              <feather-icon
                                icon="UploadCloudIcon"
                                size="30"
                                color="blue"
                              ></feather-icon>
                            </span>
                          </b-button>
                        </div>
                      </div>
                      <div v-for="(file, index) in filePreviews" :key="index">
                        <div
                          v-if="filePreviews.length > 0"
                          style="position: relative;"
                        >
                          <div
                            style="position: relative; display: inline-block;display: flex;
                            justify-content: center;
                            align-items: center;"
                          >
                            <img
                              :src="file.sourceUrl"
                              alt="Preview"
                              class="preview-image"
                            />
                            <button
                              @click="removeFile(index, file.publicUrl)"
                              variant="flat-primary"
                              style="position: absolute; top: -2px; z-index: 5; border: none; background:black; margin-left:150px; border-radius:50px;opacity:0.7"
                            >
                              <feather-icon
                                icon="XIcon"
                                size="10"
                                color="white"
                                style="margin-top:-2px"
                              ></feather-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="fileSizeError" class="error-message">
                    {{ fileSizeError }}
                  </p>

                  <div
                    style=" margin-top:5px;width: 420px; background-color: #c9c9c948; border: 1px solid lightgray;"
                  >
                    <ul class="bullet-list">
                      <li>
                        <b>Only one image is allowed to be added in post.</b>
                      </li>
                      <li>
                        <b
                          >Image size with minimum value 250px/250px(w/h) is
                          allowed.</b
                        >
                      </li>
                    </ul>
                  </div>
                </b-form-group>
                <b-form-group class="mt-1">
                  <label for="client-name">Add a Button (Optional)</label>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon
                        icon="PieChartIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-select
                      placeholder="Select a Button Type"
                      v-model="selectedButton"
                      :options="add_a_button_options"
                    />
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  class="mt-1"
                  v-if="
                    this.selectedButton !== 'ACTION_TYPE_UNSPECIFIED' &&
                      this.selectedButton !== 'CALL'
                  "
                >
                  <label for="client-name">Link For Your Button</label>
                  <b-form-input
                    placeholder=""
                    v-model="buttonUrl"
                  ></b-form-input>
                  <label for="client-name">(Example:www.google.com)</label>
                </b-form-group>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              v-if="this.isEdit"
              class="py-1 mt-1 next-button"
              variant="primary"
              @click="openEditModal"
              :disabled="isBtnDisabled"
            >
              Next <feather-icon icon="ArrowRightIcon"></feather-icon>
            </b-button>
            <b-button
              v-if="!this.isEdit"
              class="py-1 mt-1 next-button"
              variant="primary"
              @click="openSecondModal"
              :disabled="isBtnDisabled"
            >
              Next <feather-icon icon="ArrowRightIcon"></feather-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
    <b-modal
      v-model="modalVisible"
      no-close-on-backdrop
      id="modal-center"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <b-card-text class="custom-modal-content">
        <div
          style="display: flex;
        justify-content: center;"
        >
          <!-- <b-spinner variant="primary" v-if="isEditLoad" small></b-spinner> -->
        </div>
        <div class="header text-right">
          <div class="header-container">
            <h3 class="app-title sm mt-1 mb-2">
              Local Post(s) 
            </h3>
            <b-button
              variant="flat-primary"
              @click="closeModal"
              style="margin-top: -10px;margin-right:-18px"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
        </div>
        <b-row> </b-row>
        <div v-if="isEmptyArray == 0" style="justify-content: center; text-align: center; margin: 20px;">
          <h4>
            Data not found!
          </h4>
        </div>
        <b-card v-for="(item, index) in this.viewData" :key="item.name">
          <div class="post-container">
            <div
              style="display: flex; justify-content: space-between; align-items: center;"
            >
              <div>
                <h5
                  style="text-align: left; text-transform:capitalize; font-weight:bold ; margin-bottom:-2px"
                >
                  {{ storeName }}
                </h5>
                <span style="font-size:10px;">
                  {{ formattedDate(item.createTime) }}
                </span>
              </div>
              <div style="margin-right: -30px;">
                <!-- <b-button variant="flat-primary">
                <feather-icon icon="EditIcon"></feather-icon> Edit
              </b-button>
              |
              <b-button
                class="custom-primary-without-border"
                @click="openDeleteModal"
              >
                <feather-icon icon="Trash2Icon"></feather-icon> Delete
              </b-button> -->
                <b-dropdown no-caret variant="white">
                  <template #button-content>
                    <feather-icon
                      size="30"
                      icon="MoreVerticalIcon"
                      class="mr-20"
                    ></feather-icon>
                  </template>
                  <b-dropdown-item
                    variant="primary"
                    @click="editLocalPost(item)"
                  >
                    <span>
                      Edit
                      <feather-icon
                        icon="EditIcon"
                        style="margin-bottom: 5px; margin-left:5px"
                      ></feather-icon> </span
                  ></b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item
                    @click="openDeleteModal(item.name)"
                    variant="danger"
                    ><span>
                      Delete
                      <feather-icon
                        icon="Trash2Icon"
                        style="margin-bottom: 5px; margin-left:5px"
                      ></feather-icon></span
                  ></b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="mt-1">
              <!-- <label style="text-align: left; font-weight:bold">Local Post Image(s)</label> -->
              <div style="background-color: black;">
                <div>
                  <div
                    v-for="image in item.media"
                    :key="image.googleUrl"
                    class="image-container-upload"
                  >
                    <img
                      :src="image.googleUrl"
                      :alt="image.googleUrl"
                      referrerpolicy="no-referrer"
                      style="height: 200px; width:250px;"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <p style="text-align: left; font-size:small">
                {{ item.summary }}
              </p>
            </div>
            <div
              style="display: flex; justify-content: space-between; align-items: center;"
              class="mt-1"
            >
              <div>
                <a
                  v-if="(item.callToAction == undefined ||
                    (item.callToAction.actionType == 'CALL' && !phoneNumber))
                  "
                ></a>
                <a
                  v-else-if="item.callToAction.actionType == 'CALL' && phoneNumber"
                  :href="'tel:' + phoneNumber"
                  target="_blank"
                  >{{ getCallActionValue(item.callToAction.actionType) }}</a
                >   
                <a v-else :href="item.callToAction.url" target="_blank">{{
                  getCallActionValue(item.callToAction.actionType)
                }}</a>
              </div>
              <div style="margin-right: -20px;">
                <feather-icon
                  @click="copy(item.searchUrl)"
                  icon="CopyIcon"
                  color="#4D21FF"
                  size="20"
                  class="mr-2"
                  style="cursor: pointer; margin-bottom:3px"
                ></feather-icon>
              </div>
            </div>
          </div>
        </b-card>
      </b-card-text>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      id="modal-center"
      hide-footer
      hide-header
      centered
      size="small"
      v-model="deleteModal"
    >
      <b-card-text>
        <div class="header" style="display:flex; justify-content:space-between">
          <div>
            <feather-icon
              @click="backToEditModalFromDeleteModal"
              icon="ArrowLeftCircleIcon"
              size="25"
              color="#FF5959"
              style="cursor: pointer;"
            ></feather-icon>
          </div>
          <div>
            <b-button
              style="margin-top: -10px; margin-right: -18px;"
              variant="flat-primary"
              @click="closeDeleteModal"
              class="disable-hover"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
        </div>
        <div class="text-center">
          <feather-icon
            icon="AlertTriangleIcon"
            color="#FF5959"
            size="60"
          ></feather-icon>
        </div>
        <div>
          <h6 class="deletemodal-text">
            Are you sure you want to delete this local post?
          </h6>
        </div>
        <div class="container">
          <ul class="bullet-list">
            <li>
              <b>Delete:</b> This will remove the post from the current store
              only.
            </li>
            <li>
              <b>Delete All:</b> This will delete the post from all/any stores
              it is associated with.
            </li>
            <li>This action cannot be undone. Proceed with caution.</li>
          </ul>
        </div>
        <div class="buttons">
          <b-button
            class="button-margin"
            style="width: 210px"
            variant="outline-secondary"
            @click="closeDeleteModal"
          >
            Cancel
          </b-button>
          <b-button
            variant="danger"
            @click="openLastDelete"
            style="width: 210px"
          >
            Continue
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      id="modal-center"
      hide-footer
      hide-header
      centered
      size="small"
      v-model="editModal"
    >
      <b-card-text>
        <div class="header" style="display:flex; justify-content:space-between">
          <div>
            <feather-icon
              @click="backToEditModal"
              icon="ArrowLeftCircleIcon"
              size="25"
              color="#4D21FF"
              style="cursor: pointer;"
            ></feather-icon>
          </div>
          <div>
            <b-button
              style="margin-top: -10px; margin-right: -18px;"
              variant="flat-primary"
              @click="closeEditClientPopUp"
              class="disable-hover"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
        </div>
        <div class="text-center">
          <feather-icon
            icon="EditIcon"
            color="#4D21FF"
            size="50"
          ></feather-icon>
        </div>
        <div>
          <h6 class="deletemodal-text">
            Are you sure you want to Edit this local post?
          </h6>
        </div>
        <div class="container">
          <ul class="bullet-list">
            <li>
              <b>Edit :</b> This will edit the post from the current store only.
            </li>
            <li>
              <b>Edit All :</b> This will edit the post from all/any stores it
              is associated with.
            </li>
          </ul>
        </div>
        <div class="buttons">
          <b-button
            class="button-margin"
            style="width: 210px"
            variant="outline-secondary"
            @click="closeEditClientPopUp"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="openLastEdit"
            style="width: 210px"
          >
            Continue
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      id="modal-center"
      hide-footer
      hide-header
      centered
      size="small"
      v-model="lastEditModal"
    >
      <b-card-text>
        <div style="float: right">
          <b-button
            variant="flat-primary"
            @click="closeLastEditModal"
            style="margin-top: -10px; margin-right: -18px"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <br />
        <div class="text-center" style="margin-top: 5px">
          <h4>Are you sure you want to Edit this local post?</h4>
        </div>
        <div class="button-container">
          <b-button variant="outline-primary" @click="editViewPost(false)">
            Edit
            <b-spinner v-if="isEditPost" small class="ml-1" variant="primary" />
          </b-button>
          <b-button variant="primary" @click="editViewPost(true)">
            Edit All
            <b-spinner v-if="isEditAllPost" small class="ml-1" variant="white"
          /></b-button>
        </div>
        <div></div>
      </b-card-text>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      id="modal-center"
      hide-footer
      hide-header
      centered
      size="small"
      v-model="lastDeleteModal"
    >
      <b-card-text>
        <div style="float: right">
          <b-button
            variant="flat-primary"
            @click="closeLastDelete"
            style="margin-top: -10px; margin-right: -18px"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <br />
        <div class="text-center" style="margin-top: 5px">
          <h4>Are you sure, you want to delete this local post?</h4>
        </div>
        <div class="button-container">
          <b-button
            variant="outline-danger"
            class="custom-primary-with-border"
            @click="deletePost(false)"
          >
            Delete
            <b-spinner v-if="isDelete" small class="ml-1" variant="danger" />
          </b-button>
          <b-button variant="danger" @click="deletePost(true)">
            Delete All
            <b-spinner v-if="isDeleteAll" small class="ml-1" variant="white"
          /></b-button>
        </div>
        <div></div>
      </b-card-text>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      v-model="secondModalVisible"
      id="modal-center"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <b-card-text>
        <div class="header text-right">
          <div class="header-container">
            <div style="margin-top: 8px; margin-left: -16px; ">
              <input
                type="text"
                v-model="searchLocation"
                placeholder="Search by fields."
              />
            </div>
            <b-button
              @click="closePostModal"
              variant="flat-primary"
              style="margin-top: -10px;margin-right:-18px"
              ><feather-icon icon="XIcon"></feather-icon
            ></b-button>
          </div>
        </div>
        <!-- <b-button
          variant="primary"
          @click="onSelectAll"
          class="mb-1"
          style="float: right; margin-top:10px"
          >Select All Location(s)
          <feather-icon icon="MapPinIcon"></feather-icon>
        </b-button> -->
        <div class="mt-1">
          <vue-good-table
            @on-selected-rows-change="selectionChanged"
            class="customize-table"
            :columns="locationCol"
            :rows="locationData"
            :search-options="{
              enabled: true,
              externalQuery: searchLocation,
            }"
            :select-options="{ enabled: true }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span style="font-size: 15px;">
                {{ props.formattedRow[props.column.field] }}</span
              >
              <!-- Rest of the table cells -->
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1"></div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    v-model="paginatedDataNumber1"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
        <b-row>
          <b-col cols="12" v-if="!isEdit" class="ml-2">
            <b-button
              class="py-1 mt-1 next-button"
              variant="primary"
              @click="createLocalPost"
            >
              Create Post
              <b-spinner
                v-if="isCreatePost"
                small
                class="ml-1"
                variant="white"
              />
            </b-button>
          </b-col>
          <b-col cols="12" v-if="isEdit">
            <b-button
              class="py-1 mt-1 next-button margin-unset-right"
              variant="primary"
              @click="editViewPost"
            >
              Update Post
              <b-spinner v-if="isEditPost" small class="ml-1" variant="white" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import {
  BModal,
  BCardText,
  BButton,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BFormTextarea,
  BRow,
  BCol,
  BFormFile,
  BPagination,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BCard,
} from "bootstrap-vue";
export default {
  props: {
    accountID: {
      type: String,
    },
    clientID: {
      type: String,
    },
    localStorageModal: {
      type: Boolean,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    isEmptyArray: {
      type: Number,
    },
    viewData: {
      type: Array,
    },
    clientName: {
      type: String,
    },
    isEditLoad: {
      type: Boolean,
      default: false,
    },
    locationId: {
      type: String,
      required: true,
    },
    storeName: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    method: { type: Function },
    client: {
      type: Object,
      default: () => {
        return {
          clientID: "",
          name: "",
          sector: null,
        };
      },
    },
  },
  computed: {
    matchedButtonOptions() {
      if (!this.viewData) {
        return [];
      }
      return this.add_a_button_options.filter((option) => {
        return this.viewData.some(
          (data) => data.callToAction.actionType === option.value
        );
      });
    },
    isExceedingLimit() {
      return this.description.length > this.maxLength;
    },
    isBtnDisabled() {
      if (
        this.selectedButton === "ACTION_TYPE_UNSPECIFIED" ||
        this.selectedButton === "CALL"
      ) {
        return this.description === "" || this.filePreviews.length === 0;
      } else {
        return (
          this.description === "" ||
          this.filePreviews.length === 0 ||
          this.buttonUrl === ""
        );
      }
    },
    locationCol() {
      return [
        {
          label: "Store code",
          field: "storeCode",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Address",
          field: "address",
          thClass: "text-center",
          class: "overflow-text",
          tdClass: "text-center",
        },
      ];
    },
  },
  data() {
    return {
      characterCount: 0,
      isEditPost: false,
      isEditAllPost: false,
      isEditAllPostValue: false,
      maxLength: 1500,
      UploadFiles: [],
      internalModal: this.localStorageModal,
      fileSizeError: "",
      imageUploadWarning: false,
      deleteImage: "",
      name: "",
      imageUrl: "",
      callUrl: "",
      createButton: false,
      updateButton: false,
      editModal: false,
      selected: [],
      selectAll: [],
      isEdit: false,
      isDelete: false,
      isDeleteAll: false,
      isCreatePost: false,
      deleteAll: false,
      deleteModal: false,
      lastDeleteModal: false,
      modalVisible: false,
      lastEditModal: false,
      paginatedDataNumber1: 1,
      pageLength: 5,
      description: "",
      searchLocation: "",
      searchLocalTerm: "",
      buttonUrl: "",
      previewImages: "",
      locationData: [],
      localPostId: "",
      locationIDs: [],
      selectedRows: [],
      selectedItems: [],
      selectOptions: {
        enabled: true,
        styles: {
          checkbox: {
            width: "16px",
            height: "16px",
          },
        },
      },
      Viewimages: [],
      text: "",
      imageUrls: [],
      files: [],
      filePreviews: [],
      secondModalVisible: false,
      selectedFileNames: "Choose Files",
      selectedButton: "ACTION_TYPE_UNSPECIFIED",
      previousSelectedButton: "ACTION_TYPE_UNSPECIFIED",
      add_a_button_options: [
        { text: "None", value: "ACTION_TYPE_UNSPECIFIED" },
        { text: "Book", value: "BOOK" },
        { text: "Order online", value: "ORDER" },
        { text: "Buy", value: "SHOP" },
        { text: "Learn More", value: "LEARN_MORE" },
        { text: "Sign up", value: "SIGN_UP" },
        { text: "Call now", value: "CALL" },
      ],
    };
  },
  components: {
    BModal,
    BCardText,
    BButton,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BFormTextarea,
    BRow,
    BCol,
    BFormFile,
    VueGoodTable,
    BPagination,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCard,
  },
  methods: {
    getCallActionValue(value) {
      return this.add_a_button_options.find((obj) => obj.value === value)[
        "text"
      ];
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      return date.toLocaleDateString("en-GB", options);
    },
    // onSelectAll() {
    //   this.locationIDs = this.locationData.map((row) => row.locationId);
    //   this.showToast(
    //     "Success",
    //     "All Location Selected Successfully.",
    //     "success"
    //   );
    // },
    openLastEdit() {
      this.editModal = false;
      this.lastEditModal = true;
    },
    copy(value) {
      if (value) {
        navigator.clipboard
          .writeText(value)
          .then(() => {
            this.showToast("Copied", "Copied search url.", "success");
          })
          .catch((error) => {
            console.error("Failed to copy search url:", error);
          });
      } else {
        this.showToast("Copied", "Search url not found.", "success");
      }
    },
    openEditModal() {
      if (
        this.previousSelectedButton != "ACTION_TYPE_UNSPECIFIED" &&
        this.selectedButton == "ACTION_TYPE_UNSPECIFIED"
      ) {
        this.selectedButton = this.previousSelectedButton;
        this.showToast(
          "Validation Error",
          "CTA can't be set to 'None' if it already has some value.",
          "danger"
        );
        return;
      }
      this.editModal = true;
      this.internalModal = false;
    },
    closeEditModal() {
      this.clear();
      this.editModal = false;
      this.$emit("closeClientPopUp", true);
      this.internalModal = false;
      this.isEdit = false;
      this.$emit("closeModal", true);
    },
    closeLastEditModal() {
      this.lastEditModal = false;
      this.clear();
      this.$emit("closeClientPopUp", true);
      this.internalModal = false;
      this.isEdit = false;
      this.$emit("closeModal", true);
    },
    updateCharacterCount() {
      this.characterCount = this.description.length;
      if (this.characterCount > 1500) {
        this.description = this.description.slice(0, 1500);
        this.characterCount = 1500;
      }
    },
    clear() {
      (this.characterCount = 0),
      (this.description = ""),
        (this.searchTerm = " "),
        (this.buttonUrl = ""),
        (this.previewImages = ""),
        (this.locationData = []),
        (this.localPostId = ""),
        (this.locationIDs = []),
        (this.selectedRows = []),
        (this.selectedItems = []),
        (this.selectedButton = "ACTION_TYPE_UNSPECIFIED"),
        (this.filePreviews = []);
    },
    editLocalPost(viewData) {
      this.isEdit = true;
      this.$emit("closeModal", true);
      this.internalModal = true;
      // this.internalModal = true;
      const originalString = viewData.name;
      this.name = viewData.name;
      const startIndex =
        originalString.lastIndexOf("/locations/") + "/locations/".length;
      const endIndex = originalString.indexOf("/localPosts/");
      if (startIndex !== -1 && endIndex !== -1) {
        var extractedString = originalString.substring(startIndex, endIndex);
      }
      this.modalVisible = false;
      this.description = viewData.summary;
      this.updateCharacterCount()
      this.previewImages = viewData.media.googleUrl;
      this.selectedButton = viewData.callToAction.actionType;
      this.previousSelectedButton = viewData.callToAction.actionType;
      this.buttonUrl = viewData.callToAction.url;
      this.locationIDs = extractedString;
      this.imageUrl = viewData.media[0].googleUrl;
      var mediaObject = {
        mediaFormat: "PHOTO",
        sourceUrl: viewData.media[0].googleUrl,
      };
      this.filePreviews.push(mediaObject);
    },
    deletePost(value) {
      this.deleteAll = value;
      if (value === true) {
        this.isDeleteAll = true;
      } else {
        this.isDelete = true;
      }
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/localPost/delete?accountID=${this.accountID}&locationID=${this.locationId}&clientID=${this.clientID}&localPostID=${this.localPostId}&deleteAll=${this.deleteAll}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.lastDeleteModal = false;
          this.isDelete = false;
          this.isDeleteAll = false;
          this.showToast("Local Post Deleted", response.data.message, "danger");
        })
        .catch((error) => {
          this.isDelete = false;
          this.isDeleteAll = false;
          this.showToast("Error", "Unknown error occured.", "danger");
          console.log(error);
        });
    },
    share(platform, url) {
      window.location.href = url;
    },
    openLastDelete() {
      this.lastDeleteModal = true;
      this.$emit("closeModal", true);
      this.deleteModal = false;
    },
    closeLastDelete() {
      this.lastDeleteModal = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.$emit("closeModal", true);
    },
    openDeleteModal(name) {
      this.localPostId = name.substring(name.lastIndexOf("/") + 1);
      this.modalVisible = false;
      this.$emit("closeModal", true);
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    selectionChanged: function(selectedRows) {
      this.locationIDs = selectedRows.selectedRows.map((value) => {
        return value.locationId;
      });
    },
    getLocationData() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/stores?accountID=${this.accountID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.locationData = response.data.stores;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createLocalPost() {
      this.isCreatePost = true;
      let postData = {
        clientID: this.clientID,
        accountID: this.accountID,
        locationIDs: this.locationIDs,
        data: {
          languageCode: "en",
          summary: this.description,
          topicType: "STANDARD",
          media: [
            {
              mediaFormat: "PHOTO",
              source_url: this.previewImages,
            },
          ],
        },
      };

      if (this.selectedButton != "ACTION_TYPE_UNSPECIFIED") {
        postData["data"]["callToAction"] = {
          actionType: this.selectedButton,
        };
        if (this.selectedButton != "CALL") {
          postData["data"]["callToAction"]["url"] = this.buttonUrl;
        }
      }

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/localPost/create`,
          postData,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isCreatePost = false;
          this.secondModalVisible = false;
          this.clear();
          this.showToast("Success", response.data.message, "success");
          this.$emit("closeClientPopUp", true);
          this.closePostModal();
          // location.reload();
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
          console.log("error.");
          this.isCreatePost = false;
        });
    },
    openSecondModal() {
      this.getLocationData();
      this.$emit("closeClientPopUp", true);
      this.secondModalVisible = true;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    closePostModal() {
      this.secondModalVisible = false;
      this.clear();
    },
    backTo1stModal() {
      this.localStorageModal = true;
      this.secondModalVisible = false;
    },
    handleFileChange(e) {
      const selectedFiles = e.target.files;
      if (selectedFiles && selectedFiles.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          const extension = file.name
            .split(".")
            .pop()
            .toLowerCase();
          if (
            (extension === "png" && file.type === "image/png") ||
            (extension === "jpeg" && file.type === "image/jpeg")
          ) {
            const file = selectedFiles[i];

            const maxFileSizeInBytes = 1 * 1024 * 1024; // 1 MB

            if (file && file.size > maxFileSizeInBytes) {
              this.fileSizeError =
                "File size exceeds the allowed limit of 1 MB.";
              e.target.value = ""; // Reset the input value
              return;
            }
            this.fileSizeError = "";
            const reader = new FileReader();
            reader.onload = (e) => {
              const mediaObject = {
                mediaFormat: "PHOTO",
                sourceUrl: e.target.result,
              };
              this.filePreviews.push(mediaObject);
            };
            reader.readAsDataURL(file);
            var formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
              formData.append("images", selectedFiles[i]);
            }
            this.imageUploadWarning = false;
          } else {
            this.imageUploadWarning = true;
            return;
          }
        }
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/gmb/localPost/upload-images`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            this.previewImages = response.data[0];
            if (response.data[0].indexOf("https") == -1) {
              response.data[0] = response.data[0].replace("http", "https");
            }
            this.filePreviews[0]["publicUrl"] = response.data[0];
          })
          .catch((error) => {
            console.log(error);
          });
        this.filterInvalidFiles();
      }
    },
    filterInvalidFiles() {
      this.filePreviews = this.filePreviews.filter(
        (preview) => preview.mediaFormat === "PHOTO"
      );
    },
    removeFile(index, publicUrl) {
      this.filePreviews.splice(index, 1);
      const deleteImg = publicUrl.substring(publicUrl.lastIndexOf("/") + 1);
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/localPost/delete/${deleteImg}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    backToViewPostModal() {
      this.modalVisible = true;
      this.internalModal = false;
      this.clear();
    },
    backToEditModal() {
      this.internalModal = true;
      this.editModal = false;
    },
    backToEditModalFromDeleteModal() {
      this.deleteModal = false;
      this.modalVisible = true;
    },
    closeClientPopUp() {
      this.clear();
      this.$emit("closeClientPopUp", true);
      this.internalModal = false;
    },
    closeEditClientPopUp() {
      this.clear();
      this.editModal = false;
      this.$emit("closeClientPopUp", true);
      this.internalModal = false;
      this.isEdit = false;
      this.$emit("closeModal", true);
      // this.clear();
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    processData() {
      if (this.action === "edit-client") {
      } else if (this.action === "add-new") {
        this.$emit("add-new", this.client);
      }
    },
    editViewPost(value) {
      this.isEditAllPostValue = value;
      if (value === true) {
        this.isEditAllPost = true;
      } else {
        this.isEditPost = true;
      }
      this.localPostId = this.name.substring(this.name.lastIndexOf("/") + 1);
      // this.isEditPost = true;
      let editData = {
        clientID: this.clientID,
        accountID: this.accountID,
        locationID: this.locationId,
        localPostID: this.localPostId,
        editAll: this.isEditAllPostValue,
        data: {
          languageCode: "en",
          summary: this.description,
          topicType: "STANDARD",
          media: [
            {
              mediaFormat: "PHOTO",
              sourceUrl: this.imageUrl,
            },
          ],
        },
      };

      if (this.selectedButton != "ACTION_TYPE_UNSPECIFIED") {
        editData["data"]["callToAction"] = {
          actionType: this.selectedButton,
        };
        if (this.selectedButton != "CALL") {
          editData["data"]["callToAction"]["url"] = this.buttonUrl;
        }
      }
      axios
        .patch(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/localPost/edit`,
          editData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.lastEditModal = false;
          this.isEditAllPost = false;
          this.isEditPost = false;
          // this.$bvModal.hide("main-modal-center");
          this.isEditPost = false;
          this.secondModalVisible = false;
          this.$emit("closeClientPopUp", true);
          this.closePostModal();
          this.clear();
          this.showToast(
            "Local Post Updated",
            response.data.message,
            "success"
          );
          this.clear();
          // location.reload();
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
          console.error(error);
        });
    },
  },
  watch: {
    localStorageModal(newVal) {
      // Update the data property when the prop changes from the parent component
      this.internalModal = newVal;
    },
    showModal(newVal) {
      // Update the data property when the prop changes from the parent component
      this.modalVisible = newVal;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.next-button {
  float: right;
  margin-right: 20px;
}
.margin-unset-right {
  margin-right: unset;
}
.header-container {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}
.preview-image {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 150px !important;
  height: 150px !important;
  border: 1px solid rgb(217, 214, 214);
}
.image-container {
  border: 1px dashed rgb(217, 214, 214);
  border-radius: 5px;
  height: auto;
  width: 100%;
}
.custom-file-input {
  display: inline-block;
  padding: 10px 15px;
  background-color: #f1f1f1;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.custom-file-input:hover {
  background-color: #e1e1e1;
}
.grid-item {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: 160px;
  font-size: 30px;
  text-align: left;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
.checkbox {
  margin: 0;
}
.check-box {
  background-color: aqua;
}
.customize-table > input[type="checkbox"] {
  background-color: green;
}
.post-container {
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
}
.button-group {
  float: right;
}
.grid {
  border: 0.5px solid rgb(217, 214, 214);
  padding: 15px 15px 15px 15px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.grid img {
  width: 100%;
  height: auto;
}
.description-container {
  border: 0.5px solid rgb(217, 214, 214);
  padding: 15px 15px 15px 15px;
  border-radius: 5px;
}
.button-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.custom-primary {
  background-color: #ff5959 !important;
  color: #ffffff !important;
  border: none;
}
.custom-primary-with-border {
  background-color: #ffffff !important;
  color: #ff5959 !important;
  border: 1px solid #ff5959 !important;
}
.custom-primary-without-border {
  background-color: #ffffff !important;
  color: #ff5959 !important;
  border: none !important;
}
.deletemodal-text {
  font-size: large;
  margin-top: 10px !important;
}
.buttons {
  margin-bottom: 15px;
  display: flex;
  gap: 20px;
  padding: 25px 25px;
  justify-content: center;
}
.container {
  margin: 20px 0 0 25px;
  width: 410px;
  background-color: #c9c9c948;
  border: 1px solid lightgray;
  padding: 10px 10px 10px 10px;
}
.bullet-list {
  list-style-type: disc;
  margin: 0;
  padding: 0 0 0 20px;
}
.bullet-list > li {
  font-size: smaller;
}
.image-container-upload {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container-upload img {
  transition: transform 0.3s;
}

.image-container-upload:hover img {
  transform: scale(1.2);
}
input[type="checkbox"] {
  width: 15px !important;
  margin: 10px !important;
}
.cardUpload {
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2px;
  max-width: 75px;
}
.custom-modal-content {
  max-height: 40rem; /* Set the maximum height value as per your requirement */
  overflow-y: auto;
  padding: 20px 50px 20px 50px;
}
.custom-modal-content .header-container {
  margin-left: unset;
}
.error-message {
  color: red;
}
.spinner-container-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: filter 0.3s ease;
}

.blur-background {
  backdrop-filter: blur(5px);
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-group {
  display: block !important;
}
.form-label {
   font-size: 12px !important;
}
</style>
